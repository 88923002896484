const _artistFolder = 'yutori';
const _artistId = '90079';
let jsonDirectory	= 'https://www.sonymusic.co.jp/json/v2/artist/';

const _news = {
  view: 10,
};

const url = {
  getQuery(url = location.search) {
    return URI.parseQuery(url);
  },
  getReferrer() {
    if(new URI(document.referrer).origin() === new URI(location.href).origin()){
      return document.referrer;
    }else{
      return undefined;
    }
  },
  getReferrerQuery() {
    if(new URI(document.referrer).origin() === new URI(location.href).origin()){
      return URI.parseQuery(new URI(document.referrer).query());
    }else{
      return undefined;
    }
  },
  removeQuery(data = [], url = location.href) {
    return new URI(url).removeQuery(data);
  },
  setQuery(data = {}, frangment = true, url = location.href) {
    if(frangment){
      return new URI(url).setSearch(data).fragment('');
    }else{
      return new URI(url).setSearch(data);
    }
  },
};

const english_month_list = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const makeDateData = ((date) => {
  let _obj = {};
  let _splitDate = date.split('.');
  _obj['origin'] = date;
  _obj['year'] = _splitDate[0];
  _obj['month'] = _splitDate[1];
  _obj['date'] = _splitDate[2];
  _obj['month_en'] = english_month_list[Number(_obj['month']) - 1];
  return _obj;
});

let resizeDisco = ((target, beforeSize, afterSize) => {
  $(target).each((i, val) => {
    let _src = $(val).attr('src');
    if (_src) {
      let _re = new RegExp(`${beforeSize}_${beforeSize}`);
      let _replace = _src.replace(_re, `${afterSize}_${afterSize}`);
      $(val).attr('src', _replace);
    }
  });
});